import React, { useState, useEffect } from "react";
import "../../styles/Setting.css";
import {
  UserCard,
  CardLayout,
  EmailForm,
  CompanyForm,
  PasswordForm,
  ModalLayout,
  AddForm,
  DeleteForm,
  EmailVerifyForm,
} from "./index";
import AlertBox from "../../components/AlertBox";
import * as API from "../../controller/UserController";
import { validatePasswordStrength } from "../../utils/Validations";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { USER_API } from "../../utils/OpenAPI";

// Local helper to fetch all sub-users using GET_ALL_BY_COMPANY_ID
const getAllSubUsers = async (clientId) => {
  try {
    const response = await axios.post(USER_API.GET_ALL_BY_COMPANY_ID, {
      "client-id": clientId,
    });
    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

const Settings = ({ user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Card states for showing/hiding different settings panels
  const [activeCard, setActiveCard] = useState(false);
  const [activeCard2, setActiveCard2] = useState(false);
  const [activeCard3, setActiveCard3] = useState(false);
  const [activeCard4, setActiveCard4] = useState(false);

  // Modal states
  const [isDelete, setIsDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [fetchedClient, setFetchedClient] = useState({});

  // Alert box states
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // Determine if the signed-in user is an admin
  const isAdmin = user?.userResponseList?.[0]?.user_admin === true;

  // Data states
  const [client, setClient] = useState(
    sessionStorage.getItem("client")
      ? JSON.parse(sessionStorage.getItem("client"))
      : {}
  );
  const [userData, setUserData] = useState({
    companyName: client.client_business_name || "",
  });

  const [form, setForm] = useState({
    email: "",
    reEnterEmail: "",
    company: "",
    reEnterCompany: "",
    oldPassword: "",
    newPassword: "",
    reEnterNewPassword: "",
  });

  // For admin: state to hold sub-user accounts
  const [subUsers, setSubUsers] = useState([]);

  // ======== 1) Fetch Client Info ========
  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        const response = await API.getClientById(
          user.userResponseList[0].user_company_id
        );
        if (response.status === "OK") {
          setIsClient(true);
          setFetchedClient(response);
          sessionStorage.setItem("client", JSON.stringify(response));
          setClient(response);
        } else {
          console.error("CLIENT NOT SET:", response);
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchClientInfo();
  }, [user]);

  // ======== 2) Additional fetch if needed ========
  useEffect(() => {
    const fetchData = async () => {
      await getClientInfo();
    };
    fetchData();
  }, []);

  // ======== 3) Fetch latest user info for UI ========
  useEffect(() => {
    const fetchLatestUserData = async () => {
      await getLatestUserInfo();
    };
    fetchLatestUserData();
  }, []);

  // ======== 4) If admin, retrieve all sub-users ========
  useEffect(() => {
    if (isAdmin && client && client.client_id) {
      getAllSubUsers(client.client_id)
        .then((response) => {
          if (response.status === "OK") {
            // Transform the API response to match the fields in UserCard
            const transformedSubUsers = response.userResponseList.map((apiUser) => ({
              id: apiUser.user_id,
              firstName: apiUser.user_first_name,
              lastName: apiUser.user_last_name,
              email: apiUser.user_email,
              role: apiUser.user_admin ? "Admin" : "User",
            }));

            // Filter out the currently logged-in user from the sub-user list
            const currentUserId = user.userResponseList[0].user_id;
            const subUsersForDisplay = transformedSubUsers.filter(
              (sub) => sub.id !== currentUserId
            );

            setSubUsers(subUsersForDisplay);
          } else {
            console.error("Error fetching sub-users:", response);
          }
        })
        .catch((err) => console.error("Error fetching sub-users:", err));
    }
  }, [client, isAdmin, user.userResponseList]);

  // ======== 5) Update userData when client info is updated ========
  useEffect(() => {
    if (client) {
      setUserData((prev) => ({
        ...prev,
        companyName: client.client_business_name || "",
      }));
    }
  }, [client]);

  // ---- Helper to fetch Client Info again
  const getClientInfo = async () => {
    const response = await API.getClientById(user.userResponseList[0].user_company_id);
    if (response.status === "OK") {
      sessionStorage.setItem("client", JSON.stringify(response));
      setClient({ ...client, response });
    } else {
      console.error("CLIENT NOT SET:", response);
    }
  };

  // ---- Helper to fetch latest user info
  const getLatestUserInfo = async () => {
    try {
      // The user ID from userResponseList:
      const userId = user.userResponseList[0].user_id;

      // Call the API with the proper JSON shape
      const response = await API.getUserById(userId);

      if (response.status === "OK") {
        setUserData((prev) => ({
          ...prev,
          ...response,
          companyName: client.client_business_name,
          user_email: user.userResponseList[0].user_email,
        }));
      } else {
        console.error("Error in getLatestUserInfo:", response);
      }
    } catch (err) {
      console.error("Exception in getLatestUserInfo:", err);
    }
  };

  // ---- OnChange for form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      // Validate password strength
      setIsPasswordValid(validatePasswordStrength(value));
    }
    setForm({ ...form, [name]: value });
  };

  // ---- 6) Submit Handlers
  const handleEmailCardSubmit = () => {
    if (form.email === "" || form.reEnterEmail === "") {
      setAlertMessage("Please fill in all the fields.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.email !== form.reEnterEmail) {
      setAlertMessage("Emails do not match");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.email === userData.user_email) {
      setAlertMessage("Please enter a new email address.");
      setAlertType("error");
      setShowAlert(true);
      return;
    } else if (!emailVerified) {
      setAlertMessage("Please click 'Send Code' to verify your email first");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    const userpayload = {
      "user-email": form.email,
      "user-id": user.userResponseList[0].user_id,
    };
    // Update primary user data
    API.updateConsumerData(userpayload).then((response) => {
      if (response?.status === "OK") {
        navigate("/settings");
        setAlertMessage("Email Changed Successfully.");
        setAlertType("success");
        setShowAlert(true);
        setUserData({ ...userData, user_email: form.email });
        setForm({ ...form, email: form.email, reEnterEmail: form.reEnterEmail });
        setEmailVerified(true);
      } else {
        console.error(response.status);
        setAlertMessage("Email could not be changed.");
        setAlertType("error");
        setShowAlert(true);
        setEmailVerified(false);
      }
    });
  };

  const handleCompanyCardSubmit = () => {
    if (form.company === "" || form.reEnterCompany === "") {
      setAlertMessage("Please fill in all the fields.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.company !== form.reEnterCompany) {
      setAlertMessage("Please make sure the company names match.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.company === userData.companyName) {
      setAlertMessage("Please enter a new company name.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    const clientPayload = {
      "client-id": client.client_id,
      "client-business-name": form.reEnterCompany,
    };
    API.updateClientData(clientPayload).then((response) => {
      if (response?.status === "OK") {
        navigate("/settings");
        setAlertMessage("Company Name Changed Successfully");
        setAlertType("success");
        setShowAlert(true);
        setClient({ ...client, client_business_name: form.company });
        setForm({
          ...form,
          company: form.company,
          reEnterCompany: form.reEnterCompany,
        });
      } else {
        navigate("/settings");
        setAlertMessage("Company Name could not be changed.");
        setAlertType("error");
        setShowAlert(true);
      }
    });
  };

  const handlePasswordCardSubmit = () => {
    if (
      form.oldPassword === "" ||
      form.newPassword === "" ||
      form.reEnterNewPassword === ""
    ) {
      setAlertMessage("Please fill all the fields");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (!isPasswordValid) {
      setAlertMessage("New password does not meet strength requirements.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.newPassword !== form.reEnterNewPassword) {
      setAlertMessage("Passwords do not match");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.oldPassword === form.newPassword) {
      setAlertMessage("You cannot enter an older password, Please enter a new Password.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    // Verify the old password
    const verifyPayload = {
      "user-email": userData.user_email,
      "user-password": form.oldPassword,
    };
    API.verifyLogin(verifyPayload).then((response) => {
      if (response?.status === "BAD_REQUEST") {
        navigate("/settings");
        setAlertMessage("Please ensure the original password is correct.");
        setAlertType("error");
        setShowAlert(true);
      } else {
        const userpayload = {
          "user-id": user.userResponseList[0].user_id,
          "user-email": userData.user_email,
          "user-password": form.newPassword,
        };
        API.updateConsumerData(userpayload).then((resp) => {
          if (resp?.status === "OK") {
            navigate("/settings");
            setAlertMessage("Password Changed Successfully");
            setAlertType("success");
            setShowAlert(true);
            setUserData({ ...userData, user_password: form.newPassword });
            setForm({
              ...form,
              oldPassword: "",
              newPassword: "",
              reEnterNewPassword: "",
            });
          } else {
            setAlertMessage("Password could not be changed.");
            setAlertType("error");
            setShowAlert(true);
          }
        });
      }
    });
  };

  // ---- 7) Deletion logic in Settings
  const handleDeleteAccount = () => {
    setIsDelete(!isDelete);
  };

  // ---- 8) Email Verification
  const handleSendCode = () => {
    if (form.email === "") {
      setAlertMessage("Please enter the email");
      setAlertType("error");
      setShowAlert(true);
      return;
    } else if (form.email !== form.reEnterEmail) {
      setAlertMessage("Emails do not match");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    setIsEmailVerify(!isEmailVerify);
  };

  // ---- 9) Alert close
  const closeAlert = () => {
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
  };

  // We no longer show the "onlyOneAdmin" warning text in the UI,
  // but we can still compute it to pass to DeleteForm if needed.
  const currentUserId = user.userResponseList[0].user_id;
  const admins = Array.isArray(subUsers)
    ? subUsers.filter((u) => u && u.role === "Admin")
    : [];
  const onlyOneAdmin =
    admins.length === 0 && isAdmin
      ? true
      : admins.length === 1 && admins.some((u) => u.id === currentUserId);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="setting-heading-main-container">
        <div className="setting-heading-container">
          <h2 className="setting-heading">Settings</h2>
          <p className="setting-para">
            Make changes to your account and manage sub-user access for subscriptions here!
          </p>
          {/* We removed the single-admin warning line here */}
        </div>
      </div>

      <CardLayout
        title={"Change Email"}
        isOpen={activeCard}
        handler={() => setActiveCard(!activeCard)}
        handleSubmit={handleEmailCardSubmit}
      >
        <EmailForm
          form={form}
          handleChange={handleChange}
          handleSendCode={handleSendCode}
          email={userData.user_email}
        />
      </CardLayout>

      {/* 
      {isClient && (
        <CardLayout
          title={"Change Company Name"}
          isOpen={activeCard2}
          handler={() => setActiveCard2(!activeCard2)}
          handleSubmit={handleCompanyCardSubmit}
        >
          <CompanyForm
            form={form}
            handleChange={handleChange}
            company={fetchedClient.client_business_name}
          />
        </CardLayout>
      )} 
      */}

      <CardLayout
        title={"Change Password"}
        isOpen={activeCard3}
        handler={() => setActiveCard3(!activeCard3)}
        handleSubmit={handlePasswordCardSubmit}
        isSubmitDisabled={!isPasswordValid}
      >
        <PasswordForm form={form} handleChange={handleChange} />
      </CardLayout>

      {/* Render the Sub-User management only if the user is an admin */}
      {isAdmin && (
        <UserCard
          isOpen={activeCard4}
          handler={() => setActiveCard4(!activeCard4)}
          handleAddBtn={() => setIsAdd(!isAdd)}
          users={subUsers}
          setUsers={setSubUsers}
          user={user}
        />
      )}

      <div className="settings-delete-btn-cont">
        <button className="settings-delete-btn" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>

      {/* Delete Modal */}
      <ModalLayout isOpen={isDelete} onClose={() => setIsDelete(!isDelete)}>
        <DeleteForm
          onClose={() => setIsDelete(!isDelete)}
          onlyOneAdmin={onlyOneAdmin}
        />
      </ModalLayout>

      {/* Add/Edit Modal */}
      <ModalLayout isOpen={isAdd} onClose={() => setIsAdd(!isAdd)}>
        <AddForm
          onClose={() => setIsAdd(!isAdd)}
          users={subUsers}
          setUsers={setSubUsers}
        />
      </ModalLayout>

      {/* Send Code Modal */}
      <ModalLayout isOpen={isEmailVerify} onClose={() => setIsEmailVerify(!isEmailVerify)}>
        <EmailVerifyForm
          onClose={() => setIsEmailVerify(!isEmailVerify)}
          setEmailVerified={setEmailVerified}
        />
      </ModalLayout>

      {showAlert && (
        <AlertBox
          message={alertMessage}
          onClose={closeAlert}
          type={alertType}
        />
      )}
    </div>
  );
};

export default Settings;
