import React from "react";

const Modal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay effect
    zIndex: 1000, // High z-index to ensure it is on top
  };

  const modalContentStyle = {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "900px",
    width: "90%",
    maxHeight: "90vh", // Ensures it does not go beyond the screen
    overflowY: "auto", // Enables scrolling if needed
    boxSizing: "border-box",
    zIndex: 1001,
  };

  const overlayStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "transparent",
    zIndex: 1000,
  };

  return (
    <div style={modalStyle}>
      <div style={overlayStyle} onClick={onClose}></div>
      <div style={modalContentStyle}>
        <div className="model-close-cont" style={{ display: "flex", justifyContent: "flex-end" }}>
          <div onClick={onClose} className="model-close-icon" style={{ cursor: "pointer" }}>
            {/* SVG close icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ width: "30px", height: "30px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
        <div className="model-text-cont" style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {content.map((singleParagraph, index) => (
            <p
              key={index}
              style={
                singleParagraph === "Terms And Conditions"
                  ? { fontSize: "30px", fontWeight: "bold", textAlign: "center" }
                  : { fontSize: "16px", textAlign: "justify", lineHeight: "1.5" }
              }
            >
              {singleParagraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
