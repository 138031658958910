import React, { useState } from "react";
import "../../../styles/UserCard.css";
import { ArrowIcon, EditIcon, DeleteIcon } from "../../../components/icons";
import { ModalLayout, AddForm } from "../index";
import AlertBox from "../../../components/AlertBox";
import axios from "axios";
import { USER_API } from "../../../utils/OpenAPI";

// Local helper to delete a sub-user using the DELETE_USER endpoint.
const deleteSubUser = async (payload) => {
  try {
    const response = await axios.delete(USER_API.DELETE_USER, { data: payload });
    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

const UserCard = ({ isOpen, handler, handleAddBtn, users, setUsers }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Delete a user based on index
  const handleDelete = (index) => {
    const userToDelete = users[index];
    deleteSubUser({ "user-id": userToDelete.id })
      .then((response) => {
        if (response.status === "OK") {
          const newUsers = [...users];
          newUsers.splice(index, 1);
          setUsers(newUsers);
        } else {
          setAlertMessage("Failed to delete sub-user.");
          setIsAlertOpen(true);
        }
      })
      .catch((err) => {
        console.error("Error deleting sub-user:", err);
        setAlertMessage("Error deleting sub-user.");
        setIsAlertOpen(true);
      });
  };

  // Edit a user's info
  const handleEdit = (subUser, index) => {
    setEditUser({ index, user: subUser });
    setIsEdit(true);
  };

  // Opens the 'Add User' modal
  const handleAddUser = () => {
    handleAddBtn();
  };

  // Callback for when a new user is successfully added in AddForm
  const handleUserAdded = (newUser) => {
    // Append the new user to the existing users array
    setUsers((prev) => [...prev, newUser]);
  };

  return (
    <div className={`setting-main-card-container ${isOpen ? "expanded" : ""}`}>
      <div className="setting-card-container" onClick={handler}>
        <div className="setting-text-container">
          <span onClick={handler} style={{ cursor: "pointer" }}>
            Secondary User(s)
          </span>
        </div>
        {isOpen ? (
          <div className="add-new-btn">
            <button onClick={handleAddUser}>Add New</button>
          </div>
        ) : (
          <div className="setting-icon">
            <ArrowIcon handler={handler} />
          </div>
        )}
      </div>

      {isOpen && (
        <div className="setting-hidden-container">
          <div className="cont-responsive-table">
            <ul className="responsive-table">
              <li className="table-header">
                <div className="col col-1">Name</div>
                <div className="col col-2">Email</div>
                <div className="col col-3">Permissions</div>
                <div className="col col-4">Actions</div>
              </li>
              {Array.isArray(users) &&
                users.map((subUser, index) => {
                  if (!subUser) return null;

                  const firstName = subUser.firstName || "";
                  const lastName = subUser.lastName || "";
                  const email = subUser.email || "";
                  const role = subUser.role || "User";

                  return (
                    <li className="table-row" key={subUser.id}>
                      <div className="col col-1" data-label="Name">
                        {firstName} {lastName}
                      </div>
                      <div className="col col-2" data-label="Email">
                        {email}
                      </div>
                      <div className="col col-3" data-label="Permissions">
                        <div className="col-3-btn">{role}</div>
                      </div>
                      <div className="col col-4" data-label="Actions">
                        <EditIcon handler={() => handleEdit(subUser, index)} />
                        <DeleteIcon handler={() => handleDelete(index)} />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="user-contact-text">
            "To Add Users in Bulk, please contact the sales team."
          </div>
        </div>
      )}

      {/* Add/Edit Modal */}
      <ModalLayout isOpen={isEdit} onClose={() => setIsEdit(false)}>
      <AddForm
  onClose={() => setIsEdit(false)}
  users={users}
  setUsers={setUsers}
  editUser={editUser}
  onUserAdded={handleUserAdded}
/>

      </ModalLayout>

      {isAlertOpen && (
        <AlertBox
          message={alertMessage}
          type="error"
          onClose={() => setIsAlertOpen(false)}
        />
      )}
    </div>
  );
};

export default UserCard;
