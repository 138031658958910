import React, { useState } from "react";
import axios from "axios";
import "../../../styles/Modal.css";
import AlertBox from "../../../components/AlertBox";
import { USER_API } from "../../../utils/OpenAPI"; // adjust if needed

const DeleteForm = ({ onClose, onlyOneAdmin }) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [alertData, setAlertData] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleChange = (e) => {
    setDeleteInput(e.target.value);
  };

  const handleSubmit = async () => {
    // 1) Check if user is the only admin
    if (onlyOneAdmin) {
      setAlertData({
        open: true,
        message:
          "Since you are the only admin, please add another sub-user as admin " +
          "or contact sales to delete the primary account and transfer ownership.",
        type: "error",
      });
      return; // Prevent further deletion
    }

    // 2) Ensure the user typed "DELETE"
    if (deleteInput !== "DELETE") {
      return setAlertData({
        open: true,
        message: "Please type DELETE to confirm",
        type: "error",
      });
    }

    try {
      // 3) Parse the session storage data
      const storedData = JSON.parse(sessionStorage.getItem("user"));
      if (!storedData) {
        throw new Error("No user data found in session storage.");
      }

      // 4) Extract the userId from userResponseList
      const userId = storedData?.userResponseList?.[0]?.user_id;
      if (!userId) {
        throw new Error("No user_id found in session storage.");
      }

      // 5) Send DELETE request with 'requestData' as an array
      await axios.delete(USER_API.DELETE_USER, {
        data: {          
              userId: userId,          
        },
      });

      // If successful, display success message
      setAlertData({
        open: true,
        message: "Account Deleted Successfully",
        type: "success",
      });
    } catch (error) {
      console.error("Error deleting account:", error);
      setAlertData({
        open: true,
        message:
          error?.response?.data?.message ||
          "Unable to delete the account. Please try again.",
        type: "error",
      });
    }
  };

  const closeAlert = () => {
    setAlertData((prev) => ({ ...prev, open: false }));
    if (alertData.message === "Account Deleted Successfully") {
      // Clear session/local storage
      sessionStorage.clear();
      localStorage.clear();

      // Redirect to homepage (or another route)
      window.location.href = "/";

      // Optionally close the DeleteForm modal
      onClose();
    }
  };

  return (
    <div className="remove-pop-main-cont">
      <div className="pop-heading">
        <h1>Delete Account</h1>
      </div>
      <h3>
        Are you sure you want to <span>delete</span> your account?
      </h3>
      <h3>
        <span>Warning:</span> Deleting your account will automatically unsubscribe you
        and terminate services immediately.
      </h3>
      <h2>
        <span>Note:</span> To delete the primary corporate account, please contact sales.
      </h2>

      <div className="delete-input">
        <input
          name="delete"
          placeholder="Please type DELETE to confirm"
          type="text"
          value={deleteInput}
          onChange={handleChange}
        />
      </div>

      <button className="remove-pop-btn" onClick={handleSubmit}>
        Delete Account
      </button>

      {alertData.open && (
        <AlertBox
          message={alertData.message}
          type={alertData.type}
          onClose={closeAlert}
        />
      )}
    </div>
  );
};

export default DeleteForm;
