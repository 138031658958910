import React, { useState } from "react";
import "../../../styles/Modal.css";
import * as API from "../../../controller/UserController";

import AlertBox from "../../../components/AlertBox";

const SUB_USER_STATIC_PW = process.env.REACT_APP_SUB_USER_STATIC_PW;

const AddForm = ({ onClose, users, setUsers, editUser }) => {
  const [form, setForm] = useState({
    firstName: editUser?.user?.firstName || "",
    lastName: editUser?.user?.lastName || "",
    email: editUser?.user?.email || "",
    roleAssigned: editUser?.user?.role || "",
  });

  // State for managing the custom alert box
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Function to format current date as "MM/DD/YYYY HH.mm.ss"
  const getFormattedDate = () => {
    const now = new Date();
    const pad = (num) => num.toString().padStart(2, "0");
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    const year = now.getFullYear();
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());
    return `${month}/${day}/${year} ${hours}.${minutes}.${seconds}`;
  };

  // Close the alert box
  const closeAlert = () => {
    setAlertMessage("");
  };

  const handleSubmit = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      form.firstName === "" ||
      form.lastName === "" ||
      form.email === "" ||
      form.roleAssigned === ""
    ) {
      setAlertMessage("Please fill all the fields");
      setAlertType("error");
      return;
    }
    if (!emailPattern.test(form.email)) {
      setAlertMessage("Please enter a valid email address");
      setAlertType("error");
      return;
    }

    // If we're editing an existing user:
    if (editUser?.user) {
      try {
        const payload = {
          "user-id": editUser.user.id,
          "user-email": form.email,
          "user-first-name": form.firstName,
          "user-last-name": form.lastName,
          "is-admin": form.roleAssigned === "Admin",
          "date-updated": getFormattedDate(),
        };

        const response = await API.updateConsumerData(payload);
        if (response && response.status === "OK") {
          const apiUser = response.userResponseList?.[0] || response.user;
          if (!apiUser) {
            setAlertMessage("No user data returned by API. Please check the response format.");
            setAlertType("error");
            return;
          }

          // Transform the returned user object into the shape your table needs
          const updatedUser = {
            id: apiUser.user_id,
            firstName: apiUser.user_first_name,
            lastName: apiUser.user_last_name,
            email: apiUser.user_email,
            role: apiUser.user_admin ? "Admin" : "User",
          };

          // Update user in the local state array
          const newUsers = [...users];
          newUsers[editUser.index] = updatedUser;
          setUsers(newUsers);
          onClose();
        } else {
          setAlertMessage("Error updating sub user. Please try again.");
          setAlertType("error");
        }
      } catch (error) {
        console.error("Error updating user:", error);
        setAlertMessage("An unexpected error occurred while updating the user.");
        setAlertType("error");
      }
    } else {
      // Adding a brand-new sub user (unchanged code)
      const client = JSON.parse(sessionStorage.getItem("client"));
      const payload = {
        "user-email": form.email,
        // Use our static env password for *all* sub users
        "user-password": SUB_USER_STATIC_PW,
        "user-first-name": form.firstName,
        "user-last-name": form.lastName,
        "is-admin": form.roleAssigned === "Admin",
        "date-added": getFormattedDate(),
        "company-id": client?.client_id,
      };

      const response = await API.saveConsumerData(payload);
      if (response && response.status === "OK") {
        const apiUser = response.userResponseList?.[0] || response.user;
        if (!apiUser) {
          setAlertMessage("No user data returned by API. Please check the response format.");
          setAlertType("error");
          return;
        }

        // Transform the returned user object into the shape your table needs
        const newUser = {
          id: apiUser.user_id,
          firstName: apiUser.user_first_name,
          lastName: apiUser.user_last_name,
          email: apiUser.user_email,
          role: apiUser.user_admin ? "Admin" : "User",
        };

        setUsers([...users, newUser]);
        onClose();
      } else {
        setAlertMessage("Error saving sub user. Please try again.");
        setAlertType("error");
      }
    }

    // Reset form fields
    setForm({
      firstName: "",
      lastName: "",
      email: "",
      roleAssigned: "",
    });
  };

  return (
    <>
      {/* If we have an alert message, show AlertBox */}
      {alertMessage && (
        <AlertBox
          message={alertMessage}
          onClose={closeAlert}
          type={alertType}
        />
      )}

      <div className="add-pop-main-cont">
        <div className="pop-heading">
          <h1>{editUser?.user ? "Update User" : "Add User"}</h1>
        </div>

        <div className="first-name-last-name-cont">
          <div className="add-input">
            <input
              name="firstName"
              placeholder="First Name"
              type="text"
              value={form.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="add-input">
            <input
              name="lastName"
              placeholder="Last Name"
              type="text"
              value={form.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="email-roleAssigned-cont">
          <div className="add-input2">
            <input
              name="email"
              placeholder="Email"
              type="email"
              value={form.email}
              onChange={handleChange}
            />
          </div>
          {/* Password field removed. It's now static. */}
          <div className="add-input2">
            <select
              name="roleAssigned"
              value={form.roleAssigned}
              onChange={handleChange}
            >
              <option value="">Select Role</option>
              <option value="Admin">Admin</option>
              <option value="User">User</option>
            </select>
          </div>
        </div>

        <button className="add-pop-btn" onClick={handleSubmit}>
          {editUser?.user ? "Update" : "Add"}
        </button>
      </div>
    </>
  );
};

export default AddForm;
