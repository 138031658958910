import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import "../../../styles/contactForm.css";
import Form from "./Form";

const ContactForm = ({ user }) => {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user?.userResponseList[0].user_email) {
      setUserEmail(user.userResponseList[0].user_email);
    }
  }, []);

  // Check if the user is logged in
  const isLoggedIn = user && user.userResponseList[0]?.user_email;

  return (
    <div className="main-container">
      <h1 className="heading">CipherTEA Support</h1>
      <div className="content-container">
        <div className="contact-header">
          <h1 className="heading2">Contact Us Now</h1>
          <div className="para">
            <p style={{ display: "block", textAlign: "center" }}>We've got you covered!</p>
          </div>
          <div className="para2">
            <p className="para2-email">
              <strong>Email:</strong> {isLoggedIn ? user.userResponseList[0].user_email : "Please sign in to view"}
            </p>
          </div>
        </div>

        {/* Blur and overlay if user is not logged in */}
        {!isLoggedIn && (
          <div
            className="blur-overlay"
            style={{
              position: "absolute",
              top: 95, // Start from the very top
              left: 0,
              right: 0,
              bottom: -100, // Cover the entire height
              backdropFilter: "blur(6px)",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              zIndex: 10, // Ensure it's above the form
            }}
            onClick={() => navigate("/login")} // Redirect to login page on click
          >
            <p style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#000" }}>
              Please Sign In To View
            </p>
          </div>
        )}

        <Form userEmail={userEmail} />
      </div>
    </div>
  );
};

export default ContactForm;