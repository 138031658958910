const validateCorporateSignupForm = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = "* required";
    }

    if (!values.lastName) {
        errors.lastName = "* required";
    }

    if (!values.email) {
        errors.email = "* required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email is invalid";
    }

    if (!values.password) {
        errors.password = "* required";
    } else if (!isPasswordValid(values.password)) {
        errors.password = "Password must adhere to requirements.";
    }

    if (!values.businessName) {
        errors.businessName = "* required";
    }

    return errors;
}

const validateLogin = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = "* required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email is invalid";
    }

    if (!values.password) {
        errors.password = "* required";
    }
    return errors;
};

const validateIndividualSignupForm = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = "* required";
    }
    if (!values.lastName) {
        errors.lastName = "* required";
    }

    if (!values.email) {
        errors.email = "* required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email is invalid";
    }

    if (!values.password) {
        errors.password = "* required";
    } else if (!isPasswordValid(values.password)) {
        errors.password = "Password must adhere to requirements.";
    }


    return errors;
}
export const isPasswordValid = (pwd) => {
    const requirements = {
        length: pwd.length >= 16,
        upperLowerCase: /[a-z]/.test(pwd) && /[A-Z]/.test(pwd),
        number: /[0-9]/.test(pwd),
        specialChar: /[^A-Za-z0-9]/.test(pwd),
        noIdentical: !/(.)\1{2}/.test(pwd),
    };
    return Object.values(requirements).every(Boolean);
};

export const isPhoneFormatValid = (phone) => {
    const phoneRegex = /^[0-9]+$/; // Regex to allow only digits
    return phoneRegex.test(phone);  // Returns true if phone is valid, false otherwise
};
export const isPhoneLengthValid = (phone) => {
    return phone.length === 10;  // Returns true if phone length is valid, false otherwise
};

const validatePasswordStrength = (password) => {
    const passwordCriteria = /^(?!.*(.)\1\1)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{16,}$/;
    return (passwordCriteria.test(password));
  };

export {
    validateCorporateSignupForm,
    validateLogin,
    validateIndividualSignupForm,
    validatePasswordStrength
}
