import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/contactForm.css";
import InputSelector from "../../../components/Selector";
import TextArea from "../../../components/TextArea";
import { getSupportCategories, submitContactUs } from "../../../controller/ContactSupportController";

const Form = () => {
  // Rename for clarity: 'isButtonDisabled' indicates whether the button is disabled
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    subCategory: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getSupportCategories().then((response) => {
      if (response?.status === "OK") {
        setCategories(response?.supportCategoriesResponseDtoList || []);
      }
    });
  }, []);

  useEffect(() => {
    if (formData?.category) {
      const selectedCategory = categories?.find((item) => item?.id == formData?.category);
      setSelectedCategory(selectedCategory || null);
      setFormData((prevFormData) => ({ ...prevFormData, subCategory: "" }));
    } else {
      setSelectedCategory(null);
    }
  }, [formData?.category, categories]);

  const navigate = useNavigate();

  // Handle change in input fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    // If description goes beyond 500 characters
    if (name === "description") {
      if (value.length > 500) {
        setIsButtonDisabled(true);  // Disable button
        setErrors({
          ...errors,
          description: (
            <span style={{ fontSize: "17px", color: "red" }}>
              Please enter less than 500 characters.
            </span>
          ),
        });
        return;
      } else {
        // Once back under 500, re-enable button (and clear the error if any)
        setIsButtonDisabled(false);
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};

    if (!formData.category) {
      newErrors.category = "Please select a category.";
    }

    if (!formData.subCategory) {
      newErrors.subCategory = "Please select a sub-category.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit form
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const user = JSON.parse(sessionStorage.getItem("user"));
    const payload = {
      message: formData.description,
      status: "TODO",
      user: {
        "user-id": user.userResponseList[0]["user_id"],
      },
      "support-categories-id": formData.category.toString(),
    };

    submitContactUs(payload).then((response) => {
      if (response?.status === "OK") {
        navigate("/ContactSupport");
      }
    });
  };

  const getCategoriesOptions = () => {
    return [
      { value: "", label: "Select a category" },
      ...categories.map((item) => ({
        value: item.id,
        label: item.display_name,
      })),
    ];
  };

  const getSubCategoriesOptions = () => {
    if (!selectedCategory)
      return [{ value: "", label: "Select a sub-category" }];
    return [
      { value: "", label: "Select a sub-category" },
      ...(
        selectedCategory?.description?.split(",")?.map((item) => ({
          value: item.trim(),
          label: item.trim(),
        })) || []
      ),
    ];
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div>
        <InputSelector
          options={getCategoriesOptions()}
          value={formData.category}
          onChange={handleChange}
          name="category"
        />
        {errors.category && <span className="error-text">{errors.category}</span>}
      </div>

      <div>
        <InputSelector
          options={getSubCategoriesOptions()}
          value={formData.subCategory}
          onChange={handleChange}
          name="subCategory"
        />
        {errors.subCategory && <span className="error-text">{errors.subCategory}</span>}
      </div>

      <div>
        <TextArea
          placeholder={"Please describe your issue."}
          value={formData.description}
          onChange={handleChange}
          name="description"
          rows={10}
        />
        {errors.description && <span className="error-text">{errors.description}</span>}
      </div>

      {/* 
        Toggle disabled with `isButtonDisabled`.
        This ensures the button is not clickable when the user goes over 500 chars.
      */}
      <div>
        <button className="button" disabled={isButtonDisabled}>Submit</button>
      </div>
    </form>
  );
};

export default Form;
