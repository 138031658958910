import React, { useState } from "react";
import * as API from "../../../controller/UserController";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";

const Form = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Button handler
  const handleSendLink = async () => {
    setError("");
    setSuccess(false);
    setLoading(true);

    try {
      const response = await API.getUserByPrimaryInfo(email);

      if (response.status === "OK") {
        setSuccess(true);
        setTimeout(onClose, 2000); // Close modal after success message
      } else {
        setError("Email Address does not match our records. Please enter your account email and try again");
      }
    } catch (err) {
      setError("Email Address Not Found");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgotPassword-container">
      <InputField
        type="text"
        name="email"
        label="Enter Email address"
        value={email}
        setValue={(e) => setEmail(e.target.value)}
      />

      {/* Error & Success Messages */}
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">Check your email for reset instructions.</p>}

      <Button
        text={loading ? "Checking..." : "Reset Password"}
        handler={handleSendLink}
        width="full"
        rounded="medium"
        disabled={loading}
      />
    </div>
  );
};

export default Form;
