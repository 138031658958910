import React, {useState}  from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import InputField from '../../components/InputField'
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { validateCorporateSignupForm } from "../../utils/Validations";
import Modal from '../../components/Modal';
import { paragraphs } from '../../staticData/TermsOfService';
import PasswordValidationBox from "./PasswordValidationBox";  // Import the validation box component
import { isPasswordValid, isPhoneFormatValid, isPhoneLengthValid } from '../../utils/Validations';
import * as API from "../../controller/UserController"; 
import AlertBox from "../../components/AlertBox";

const BusinessForm = () => {
  const initialValues = {
    firstname: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    businessName: "",
  };

  const [password, setPassword] = useState("");  // State to manage password input
  const [showAlert, setShowAlert] = useState(false);  // State to control the AlertBox visibility
  const [alertMessage, setAlertMessage] = useState("");  // State to hold the alert message
  const [alertType, setAlertType] = useState("");  // State to hold the alert type (success or error)
  const navigate = useNavigate();

  const handleSubmit = (values, { setSubmitting }) => {
    // Validate phone number before submitting
    if (values.phone) {
      if (!isPhoneFormatValid(values.phone)) {
        setAlertMessage("Phone number must contain only numeric values.");
        setShowAlert(true);
        return; // Stop form submission if phone number is invalid
      } if (!isPhoneLengthValid(values.phone)) {
        setAlertMessage("Phone number must be 10 digits.");
        setShowAlert(true);
        return; // Stop form submission if phone number is invalid
      } 
    }
    const clientpayload = {
      "client-business-name": values.businessName,
      "num-of-users": "1",
      "date-added": new Date().toLocaleDateString(),
      "user-object":{
        "user-email": values.email,
        "user-password": values.password,
        "user-first-name": values.firstname,
        "user-last-name": values.lastName,
        "user-phone": values.phone,
        "date-added": new Date().toLocaleDateString(),
      }
    };
    API.SaveBusinessData(clientpayload).then((response) => {
      console.log(response);
      if (response?.status === "OK") {
        navigate("/login");
      } else if(response?.status === "BAD_REQUEST") {
        setAlertMessage("Username or Password does not meet format requirements. Please try again.");
        setAlertType("error");
        setShowAlert(true);
      } else if (!response) {
        setAlertMessage("Server is unresponsive. Please try again later.");
        setAlertType("error");
        setShowAlert(true);
      }
      else {
        setAlertMessage("Business Name Or Email Already Exists. Please try again.");
        setAlertType("error");
        setShowAlert(true);
      }
    }).catch((error) => {
      setAlertMessage("An error occurred: " + error.message);
      setAlertType("error");
      setShowAlert(true);
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
      setIsOpen(false);
  }

  const closeAlert = () => {
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
  };

  return (
    <>
    <Formik
      initialValues={initialValues}
      validate={(values) => validateCorporateSignupForm(values)}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <div class="sign-up-form">
            <div class="input-container">
              <InputField
                type="text"
                label="First Name"
                name="firstname"
                value={values.firstname}
                setValue={handleChange}
                errorMessage={errors.firstname}
              />
              <InputField
                type="text"
                label="Last Name"
                name="lastName"
                value={values.lastName}
                setValue={handleChange}
                errorMessage={errors.lastName}
              />
              <InputField
                type="text"
                label="Phone (optional)"
                name="phone"
                value={values.phone}
                setValue={handleChange}
                required={false}
                errorMessage={errors.phone}
              />
              <InputField
                type="text"
                label="Business Name"
                name="businessName"
                value={values.businessName}
                setValue={handleChange}
                errorMessage={errors.businessName}
              />
              <InputField
                type="text"
                label="Email"
                name="email"
                value={values.email}
                setValue={handleChange}
                errorMessage={errors.email}
              />
              <InputField
                      type="password"
                      label="Password"
                      name="password"
                      value={password}
                      setValue={(e) => {
                        setPassword(e.target.value);  // Update the password state
                        setFieldValue('password', e.target.value);  // Ensure Formik's state is updated
                      }}
                      errorMessage={errors.password && errors.password.message}
                    />
                    {password && !isPasswordValid(password) && (
                      <div style={{ position: 'relative' }}>
                        <PasswordValidationBox password={password} />
                          </div>
                        )}
            </div>

            <div class="square-box-box">
                <p class="message">By clicking sign-up, I agree to the
                {" "}
                <span className="termsOfService-link" onClick={() => setIsOpen(true)} style={{textDecoration: "underline", color: "blue"}}>
                  Terms of Service
                </span>{" "}
                and{" "} <Link to="/privacy" style={{textDecoration: "underline", color: "blue"}}>Privacy Policy</Link> <br></br> </p>
                <p>of Ciphertea Securities Inc</p>
            </div>

            <div class="btn-links-contianer">
              <Button text={"Sign Up"} disabled={false}/>
              <div className="not-account">
                <span class="link">Already have an account?</span>
                <Link to="/login" className="link">
                  <span class="link-signup"> Log in</span>
                </Link>
              </div>
            </div>


          </div>

          {/* Modal for Terms of Service */}
          <Modal isOpen={isOpen} onClose={onClose} content={paragraphs} />
        </form>
      )}
    </Formik>
    {/* AlertBox Component */}
    {showAlert && (
        <AlertBox message={alertMessage} onClose={closeAlert} type={alertType} />
      )}
    </>
  );
};

export default BusinessForm;
