const paragraphs = [
   `Terms And Conditions`,
   <hr></hr>,
   <font size="3">
   `By using CipherTEA's software and services ("Service"), you agree to these Terms of Service
   ("Terms"). Please read them carefully and keep a copy for reference. All purchases are final and
   non-refundable. You are responsible for maintaining the confidentiality of your account and for
   all activities under it. CipherTEA is not liable for unauthorized access due to theft or
   misappropriation of your account. Accounts are non-transferable, and CipherTEA may refuse
   service, terminate accounts, or edit content at its discretion.`,
   `CipherTEA does not collect personal information from children under thirteen. By using our
   Service, you confirm your acceptance of these Terms. If you disagree, do not use the Service.
   We grant a limited, non-transferable license for personal or internal business use. All intellectual
   property rights belong to CipherTEA.`,
   `We protect your data with industry-standard security measures but are not responsible for
   breaches on other platforms. You must keep your account credentials confidential and notify us
   of unauthorized use. Refer to our Privacy Policy for data collection and use.`,
   `CipheTEA reserves the right to terminate your access to all services at any time, without notice or cause. Users outside the USA must
   comply with local laws. CipherTEA is not liable for indirect or consequential damages from using
   our Service.`,
   </font>


]

export {paragraphs};