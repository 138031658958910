//All the API endpoints are in this file.

const isDevelopment = process.env.REACT_APP_NODE_ENV === "development";

const BASE_URL = isDevelopment 
    ? process.env.REACT_APP_USER_SERVICE_DOMAIN_URL_DEV
    : process.env.REACT_APP_USER_SERVICE_DOMAIN_URL_LOCAL;
    

export const USER_API = {
    VERIFY_LOGIN: `${BASE_URL}/ct/user-management/user/verify-login`,
    SAVE_CONSUMER_DATA: `${BASE_URL}/ct/user-management/user/save`,
    UPDATE_USER_DATA: `${BASE_URL}/ct/user-management/user/update`,
    GET_USER_PERSONAL_DATA: `${BASE_URL}/ct/user-management/user/get-by-primary-info`,
    GET_USER_BY_ID: `${BASE_URL}/ct/user-management/user/get-by-id`,
    SAVE_CLIENT_BUSINESS_DATA: `${BASE_URL}/ct/user-management/client/save`,
    GET_CLIENT_WELCOME_MESSAGE: `${BASE_URL}/ct/user-management/client`,
    GET_CLIENT_BY_ID: `${BASE_URL}/ct/user-management/client/get-by-id`,
    GET_CLIENT_BY_PRIMARY_INFO: `${BASE_URL}/ct/user-management/client/get-by-primary-info`,
    DELETE_CLIENT: `${BASE_URL}/ct/user-management/client/delete`,
    UPDATE_CLIENT: `${BASE_URL}/ct/user-management/client/update`,
    GET_USER_BY_COMPANY_ID : `${BASE_URL}/ct/user-management/client/get-user-by-company-id`,
    GET_ALL_BY_COMPANY_ID: `${BASE_URL}/ct/user-management/user/get-all-by-company-id`,
    DELETE_USER: `${BASE_URL}/ct/user-management/user/delete`,


};

export const SUPPORT_API = {
    GET_CATEGORIES: `${BASE_URL}/ct/user-management/support/get-support-categories-lookup`,
    SUBMIT_CONTACT_US: `${BASE_URL}/ct/user-management/support/save`,
    // Add other support-related endpoints here
};

export const CRYPTOGRAPHY_CORE_LIB_DOMAIN_URL = isDevelopment ?
    process.env.REACT_APP_CRYPTOGRAPHY_CORE_LIB_URL_DEV : process.env.REACT_APP_CRYPTOGRAPHY_CORE_LIB_URL_LOCAL;